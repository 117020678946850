import { Button } from "$/components/Button";
import { useUser } from "$/state/user";
import { Mixpanel } from "$/tracking";
import { ArrowRight } from "lucide-react";

export const StarterChoiceCard = () => {
  const user = useUser();

  return (
    <div className="flex flex-col bg-white p-4 rounded-xl w-full md:w-1/2 lg:w-1/3">
      <div className="flex flex-col items-center text-center gap-x-4 pt-2">
        <h3 className="text-2xl font-semibold">Exploration Session</h3>
        <img src={"/images/Tools.svg"} alt={"Head"} className="w-2/3" />
        <p className="m-0 pt-2">
          Not exactly sure what coaching is, or what to bring to a session? Try this one off 45
          minute session with a coach, where you can discover what you want to work on through
          coaching.
        </p>
      </div>
      <div className="flex justify-center pt-2">
        <a href={"/book-coaching/single-session?explore=true"}>
          <Button
            primary
            onClick={() => {
              Mixpanel.track("Book an Exploration Session clicked", {
                from: "Book Coaching",
                user: user.currentUser,
              });
            }}
            rightIcon={<ArrowRight size={18} />}
          >
            Book an Exploration Session
          </Button>
        </a>
      </div>
    </div>
  );
};
