import cn from "classnames";
import { diagnosisQuizSections } from "./diagnosisStates";

const DiagnosisQuizProgressSection = ({
  label,
  value,
  end = false,
}: {
  label: string;
  value: number;
  end?: boolean;
}) => {
  return (
    <div className={cn("flex flex-col text-center basis-1/4", { "mr-2": !end })}>
      <div
        className={cn("flex items-center justify-center mb-2 text-sm md:text-base min-h-[40px]", {
          "font-semibold": value > 0 && value < 100,
        })}
      >
        {label}
      </div>
      <progress
        value={value}
        max="100"
        className="w-full h-[5px] progress-filled:bg-yellow progress-unfilled:rounded-md progress-unfilled:bg-white"
      />
    </div>
  );
};

export const DiagnosisQuizProgressBar = ({
  categoryProgress,
  questionProgress,
}: {
  categoryProgress: number;
  questionProgress: number;
}) => {
  const section1 = diagnosisQuizSections["career-progression"].length;
  const section2 = diagnosisQuizSections["communication-skills"].length;
  const section3 = diagnosisQuizSections["work-life-balance"].length;
  const section4 = diagnosisQuizSections["clear-goals"].length;
  const section5 = diagnosisQuizSections["relationships"].length;

  const progress = questionProgress + categoryProgress * 3;

  const careerProgression = (progress / section1) * 100;
  const communicationSkills = ((progress - section1) / section2) * 100 + 5;
  const workLifeBalance = ((progress - section1 - section2) / section3) * 100 + 5;
  const clearGoals = ((progress - section1 - section2 - section3) / section4) * 100 + 5;
  const relationships =
    ((progress - section1 - section2 - section3 - section4) / section5) * 100 + 5;

  return (
    <div className="flex w-full">
      <DiagnosisQuizProgressSection
        value={careerProgression > 0 ? careerProgression : 5}
        label="Career progression"
      />
      <DiagnosisQuizProgressSection value={communicationSkills} label="Communication skills" />
      <DiagnosisQuizProgressSection value={workLifeBalance} label="Work-life balance" />
      <DiagnosisQuizProgressSection value={clearGoals} label="Clear goals" />
      <DiagnosisQuizProgressSection value={relationships} label="Relationships" end={true} />
    </div>
  );
};
