import cn from "classnames";
import { useState } from "react";

const Connector = () => {
  return (
    <div className="w-full align-center items-center align-middle content-center flex z-10">
      <div className="w-full bg-lightBley opacity-40 rounded items-center align-middle align-center flex-1">
        <div className="bg-lightBley opacity-40 text-xs leading-none py-[2px] text-center rounded w-full" />
      </div>
    </div>
  );
};

const stages = [
  { start: 0, end: 3, color: "red", text: "I struggle" },
  { start: 4, end: 6, color: "orange" },
  { start: 7, end: 8, color: "lightGreen" },
  { start: 9, end: 10, color: "green", text: "I excel" },
];

interface StepProps {
  dirty: boolean;
  selected: boolean;
  onClick: () => void;
  step: number;
}

const Step = ({ dirty, selected, onClick, step }: StepProps) => {
  const curr = stages.find((stage) => step >= stage.start && step <= stage.end);

  const showLabel = selected || (!dirty && (step === 0 || step === 10));
  return (
    <div className="relative">
      {showLabel && (
        <div
          className={cn("absolute -top-10", {
            "-left-[50%]": dirty,
            "-left-[200%]": !dirty,
          })}
        >
          <div
            className={cn("flex text-sm whitespace-nowrap px-2 gap-x-1 rounded-md", {
              "bg-beige": !dirty && !selected,
              "bg-red text-white": dirty && curr?.color === "red",
              "bg-orange": curr?.color === "orange",
              "bg-bottleGreen text-white": dirty && curr?.color === "green",
              "bg-lightGreen text-white": dirty && curr?.color === "lightGreen",
            })}
          >
            <span className="font-bold">{step}</span> {!selected && curr?.text}
          </div>
        </div>
      )}
      <div className="flex-1 cursor-pointer" onClick={onClick}>
        <div
          className={cn(
            "w-3 h-3 border-2 border-grey-light mx-auto rounded-full text-lg text-white flex items-center z-20 relative",
            {
              "bg-lightBley": !selected,
              "scale-[2.5] md:scale-[3]": selected,
              "bg-red": selected && curr?.color === "red",
              "bg-orange": selected && curr?.color === "orange",
              "bg-bottleGreen": selected && curr?.color === "green",
              "bg-lightGreen": selected && curr?.color === "lightGreen",
            },
          )}
        />
      </div>
    </div>
  );
};

interface DiagnosisQuizScoringProps {
  onChange: (value: number) => void;
  selected: number | null;
  setSelected: (value: number) => void;
  dirty: boolean | null;
  setDirty: (value: boolean) => void;
}

export function DiagnosisQuizScoring({
  onChange,
  selected,
  setSelected,
  dirty,
  setDirty,
}: DiagnosisQuizScoringProps) {
  const stepArr = Array.from({ length: 11 });
  return (
    <div className="mx-auto my-4 border-b-2 pb-4">
      <div className="flex pb-3 items-center">
        {stepArr.map((_, i) => (
          <>
            <Step
              key={i}
              selected={i === selected}
              onClick={() => {
                onChange(i);
                setDirty(true);
                setSelected(i);
              }}
              step={i}
              dirty={dirty}
            />
            {stepArr.length - 1 !== i && <Connector />}
          </>
        ))}
      </div>
    </div>
  );
}
