import { ListSessionsQuery, SessionState, useCountSessionsQuery } from "$/graphql/types.generated";
import { useIsMobile } from "$/hooks";
import { google } from "calendar-link";
import cn from "classnames";
import { CirclePlus, Clock, Video, XCircle } from "lucide-react";
import { DateTime } from "luxon";
import { useNavigate } from "react-router";
import { Button } from "../Button";
import { getAvatarUrl } from "../CoachAvatar";
import { BookColor, Heart, MapPin, Star } from "../Icons";
import { useUser } from "$/state/user";
import { useGetProgrammeQuizQuery } from "$/graphql/storyblok/types.generated";

export type Session = NonNullable<NonNullable<ListSessionsQuery["listSessions"]>[number]>;

// const getGoogleCalendarLink = (session: Session) => {
//   let programmeTagline = "Ad-hoc coaching session";

//   if (session.userPackage?.plan) {
//     programmeTagline = "Coaching programme session";
//   }

//   const details = `${programmeTagline}
//   <br/>
// <b>Please use the follow links if you need to cancel or reschedule:</b>

// <a href="https://app.morehappi.com/sessions/${session.id}/cancel">Cancel session</a>
// <a href="https://app.morehappi.com/coach/${session?.coach?.handle}?r=${session.id}">Reschedule session</a>`;

//   return google({
//     title: `More Happi coaching call with ${session?.coach?.displayName}`,
//     start: DateTime.fromISO(session.startsAt).toISO(),
//     end: DateTime.fromISO(session.endsAt).toISO(),
//     location: session.location ?? "",
//     description: details,
//   });
// };

export interface SessionCardProps {
  session: Session;
  timescale: "past" | "future";
  hideActions?: boolean;
  hideSubActions?: boolean;
  onReview?: (id: string) => void;
  placeAnotherText?: string;
  onPlaceAnother?: () => void;
}

export const CoacheeSessionCard = ({
  session,
  timescale,
  hideActions,
  hideSubActions,
  onReview = () => null,
  placeAnotherText = "Place another booking",
  onPlaceAnother,
}: SessionCardProps) => {
  const navigate = useNavigate();
  const { currentUser } = useUser();
  const isMobile = useIsMobile();
  const isFuture = timescale === "future";
  const isWork = session?.categories?.some(({ category }) => category.label === "work");
  const programmeQuiz = useGetProgrammeQuizQuery({ id: `programmes/${session.userPackage?.plan}` });

  const { data: totalSessionCountProgramme } = useCountSessionsQuery({
    where: {
      startsAt: { lte: session.startsAt },
      state: { in: [SessionState.Confirmed, SessionState.ConfirmedCoach] },
      userPackageId: { equals: session.userPackage?.id },
    },
  });

  return (
    <div className="max-w-[600px] md:w-[600px]">
      <div className="border border-solid border-camel rounded-xl">
        <div className="border-0 border-b border-solid border-camel flex flex-wrap">
          <section className="w-full md:w-[27.5%] border-0 border-b md:border-b-0 border-solid border-camel py-2 px-4 flex justify-center md:border-r ">
            <div className="flex flex-col md:m-auto">
              <div className="text-lg">{DateTime.fromISO(session.startsAt).toFormat("ccc")}</div>
              <div className="text-5xl font-bold">
                {DateTime.fromISO(session.startsAt).toFormat("dd")}
                <span className="text-lg font-normal ml-1">
                  {DateTime.fromISO(session.startsAt).toFormat("MMM")}
                </span>
              </div>
            </div>
          </section>
          <section className="w-full md:w-[27.5%] border-0 md:border-r border-solid border-camel py-2 px-4 flex justify-center">
            <div className="flex flex-col text-center md:w-full md:m-auto">
              <div className="flex justify-between text-lg md:w-full">
                <p className="m-0">{DateTime.fromISO(session.startsAt).toFormat("a")}</p>
                <p className="m-0">
                  GMT
                  {DateTime.fromISO(session.startsAt).offset !== 0 &&
                    DateTime.fromISO(session.startsAt).toFormat("Z")}
                </p>
              </div>
              <div className="text-5xl font-bold">
                {DateTime.fromISO(session.startsAt).toFormat("h:mm")}
              </div>
            </div>
          </section>
          <section className="basis-full md:basis-[45%] text-lg">
            <div className="border-0 border-t md:border-t-0 border-b border-solid border-camel h-1/2 py-2 px-6 flex items-center gap-x-1 justify-center md:justify-start">
              {session?.userPackage ? (
                <div className="h-1/2 flex m-auto items-center gap-x-2 justify-center md:justify-start text-black">
                  <p className="h-1/2 flex m-auto items-center">
                    <BookColor />
                  </p>
                  <p className="h-1/2 flex m-auto items-center ">
                    Programme session{" "}
                    {!session.cancelledAt &&
                      `${totalSessionCountProgramme?.countSessions} / ${session.userPackage?.sessionCount}`}
                  </p>{" "}
                </div>
              ) : (
                <p className="h-1/2 flex m-auto items-center gap-x-2 justify-center md:justify-start text-black">
                  {isWork ? <BookColor /> : <Heart color="red" fill="red" />}
                  {isWork ? "Work" : "Personal"} coaching session
                </p>
              )}
            </div>
            {session.cancelledAt ? (
              <div className="flex w-full h-[50%] justify-center items-center bg-[#d53c3c]">
                Session cancelled
              </div>
            ) : session.noShow ? (
              <div className="flex w-full h-[50%] justify-center items-center bg-[#d53c3c]">
                No show
              </div>
            ) : isFuture ? (
              <a
                className="h-1/2 py-2 px-6 flex items-center gap-x-2 justify-center md:justify-start text-black"
                href={!session.externalId ? session.location ?? "#" : "#"}
                target="_blank"
                rel="noreferrer"
              >
                <MapPin fill="#006A39" size={14} /> Online via Google Meet
              </a>
            ) : (
              <div className="flex w-full h-[50%] justify-center items-center">
                {session.externalId ? (
                  <div />
                ) : !session?.sessionReview || session.sessionReview?.tookPlace ? (
                  <>
                    {Array.from({ length: 5 }).map((_, i) => (
                      <Star
                        key={`star-${i}`}
                        stroke="#D5881C"
                        fill={(session.sessionReview?.coachRating || 0) > i ? "#D5881C" : "none"}
                        onClick={() =>
                          session.sessionReview?.coachRating ? null : onReview(session.id)
                        }
                        className={cn({
                          "cursor-pointer": !session.sessionReview?.coachRating,
                        })}
                        size={25}
                      />
                    ))}
                  </>
                ) : (
                  <div> Session {session.cancelledAt ? "cancelled" : "did not take place"} </div>
                )}
              </div>
            )}
          </section>
        </div>
        <div className="w-full flex bg-white rounded-b-xl">
          <section className="w-1/3 h-auto rounded-bl-xl flex justify-center">
            <img
              className="rounded-full md:rounded-none md:rounded-bl-xl object-cover w-20 h-20 mt-2 ml-4 md:w-[100%] md:h-auto md:m-0"
              src={getAvatarUrl(`${session.coach!.id}/thumbnail.jpg`)}
              alt={session?.coach?.displayName || "Coach profile"}
            />
          </section>
          <section className="w-full md:w-2/3 bg-white rounded-b-xl p-2 md:py-4 flex flex-col gap-y-2 justify-between">
            <div className="px-4 min-h-20">
              <h3 className="text-xl md:text-2xl mb-0">{session?.coach?.displayName}</h3>
              <h4 className="text-sm md:text-lg">{session?.coach?.headline}</h4>
              {session?.userPackage?.plan && programmeQuiz.isFetched && (
                <h5>
                  Programme:{" "}
                  {programmeQuiz.data.PageItem?.content?.docsPage ? (
                    <a
                      href={programmeQuiz.data.PageItem.content.docsPage}
                      target="_blank"
                      rel="noreferrer"
                      className="text-midnight underline hover:no-underline"
                    >
                      <span className="font-semibold">
                        {programmeQuiz.data.PageItem.content.displayName}
                      </span>
                    </a>
                  ) : (
                    <span className="font-semibold">
                      {programmeQuiz.data.PageItem?.content?.displayName}
                    </span>
                  )}{" "}
                  ({session?.userPackage?.sessionCount} sessions)
                </h5>
              )}
              {session.packages?.length > 0 && (
                <h5>Topic: {session.packages.map((p) => p.package.label).join(", ")}</h5>
              )}
            </div>
            {!hideActions && (
              <div className="flex gap-2 md:gap-y-0 justify-end ml-[-80px] md:m-0">
                {isFuture ? (
                  <>
                    {!session.cancelledAt && !session.externalId && (
                      <a
                        href={!session.externalId ? session.location ?? "#" : "#"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button
                          tertiarySolid
                          small={isMobile}
                          large={!isMobile}
                          className="text-sm md:text-base"
                        >
                          <Video size="1.125rem" /> Join Google Meet
                        </Button>
                      </a>
                    )}
                    <Button
                      primary
                      small={isMobile}
                      large={!isMobile}
                      className="text-sm md:text-base"
                      onClick={() => {
                        if (onPlaceAnother) {
                          onPlaceAnother();
                        } else {
                          if (session?.userPackage?.plan) {
                            return navigate("/?showScheduler=true");
                          }
                          navigate(`/coach/${session.coach?.handle}`);
                        }
                      }}
                    >
                      <CirclePlus size="1.125rem" />
                      {placeAnotherText}
                    </Button>
                  </>
                ) : (
                  <>
                    {!session?.sessionReview?.id && !session.externalId && !session.cancelledAt ? (
                      <Button
                        tertiary
                        small={isMobile}
                        large={!isMobile}
                        className="text-sm md:text-base"
                        onClick={() => onReview(session.id)}
                      >
                        Review session
                      </Button>
                    ) : (
                      <div />
                    )}
                    {!session?.userPackage && currentUser?.role !== "individual" && (
                      <a href={`/coach/${session.coach?.handle}`}>
                        <Button
                          primary
                          small={isMobile}
                          large={!isMobile}
                          className="text-sm md:text-base"
                        >
                          <CirclePlus size="1.125rem" />
                          Book again
                        </Button>
                      </a>
                    )}
                  </>
                )}
              </div>
            )}
          </section>
        </div>
      </div>
      {isFuture && !session.cancelledAt && !hideSubActions && !session.externalId && (
        <div className="flex justify-between md:justify-end gap-x-4  px-2 py-1 text-lg">
          <a
            className="flex items-center gap-x-1 underline"
            href={`/coach/${session.coach?.handle}?r=${session.id}`}
            target="_blank"
            rel="noreferrer"
          >
            <Clock size="1.125rem" color="green" /> Reschedule session
          </a>
          <a
            className="flex items-center gap-x-1 underline"
            href={`/sessions/${session.id}/cancel`}
            target="_blank"
            rel="noreferrer"
          >
            <XCircle size="1.125rem" color="red" /> Cancel session
          </a>
        </div>
      )}
    </div>
  );
};
