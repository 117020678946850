import { Layout } from "antd";
import { HtmlMeta } from "$/components/HtmlMeta";
import { IPageBaseProps } from "$/interfaces";
import { TrainingCard } from "./TrainingCard";

interface IProps extends IPageBaseProps {}

export const Training: React.FC<IProps> = () => {
  return (
    <Layout>
      <HtmlMeta title="More Happi Training" />
      <div className="w-full flex flex-col justify-center items-center py-10 px-4">
        <h1 className="text-3xl font-semibold">More Happi Training</h1>
        <h2 className="text-lg text-center md:text-xl !text-darkGrey xl:max-w-[900px] xl:mx-auto font-normal">
          As a More Happi member, you get free access to monthly online workshops and training.
        </h2>
        <h2 className="text-lg text-center md:text-xl !text-darkGrey xl:max-w-[900px] xl:mx-auto font-normal">
          Register to save your space!
        </h2>
        <h2 className="text-lg text-center md:text-xl !text-darkGrey xl:max-w-[900px] xl:mx-auto">
          Communication Training with guest speaker Amy Tez
        </h2>
        <div className="flex flex-col gap-y-6">
          <TrainingCard
            title="Speak like a leader: Master the art of powerful communication"
            date="2025-05-14T13:00:00Z"
            description="Join international leadership communications specialist Amy Tez to gain practical communication skills you can apply immediately in your role. Learn powerful storytelling techniques that command attention, inspire action, and elevate your leadership presence."
            speaker="Guest speaker: Amy Tez"
            imageSrc="/images/AmyTezNew.png"
            href="https://share.hsforms.com/1QJaJvNK0SDGd9pySHeFyeg57z74"
            altText="Amy Tez"
            sessionType="Communication training (1hr)"
          />
        </div>
      </div>
    </Layout>
  );
};
