import React from "react";
import { Layout } from "antd";
import { HtmlMeta } from "$/components/HtmlMeta";
import { IPageBaseProps } from "$/interfaces";
import { ChoiceCard } from "./components/ChoiceCard";
import { useSearchParams } from "react-router-dom";

interface IProps extends IPageBaseProps {}

export const BookSingleSession: React.FC<IProps> = () => {
  const [searchParams] = useSearchParams();
  const explore = searchParams.get("explore") === "true";

  return (
    <Layout>
      <HtmlMeta title="Book a session" />
      <div className="w-full flex flex-col justify-center items-center my-10">
        {explore ? (
          <div className="flex flex-col text-center justify-center px-4">
            <h1 className="text-3xl font-semibold"> Book a 45 minute exploration session</h1>
            <h2 className="text-lg md:text-xl !text-darkGrey  xl:max-w-[750px] xl:mx-auto font-normal">
              Explore different topics, uncover your blind spots and make a plan with a Coach.
            </h2>
            <h2 className="text-lg md:text-xl !text-darkGrey  xl:max-w-[750px] xl:mx-auto font-normal">
              Would you like to select the coach you will work with, or find a time that suits you
              best, regardless of the coach?
            </h2>
          </div>
        ) : (
          <div className="flex flex-col text-center justify-center px-4">
            <h1 className="text-3xl font-semibold"> Book a single 45 minute session</h1>
            <h2 className="text-lg md:text-xl !text-darkGrey  xl:max-w-[750px] xl:mx-auto font-normal">
              Would you like to select the coach you will work with, or find a time that suits you
              best, regardless of the coach?
            </h2>
          </div>
        )}
        <div className="w-3/4 flex flex-col md:flex-row justify-around pt-12 gap-8 md:gap-y-0">
          <ChoiceCard
            title="Choose by Coach"
            description="For when you have a specific coach you'd like to work with, or you'd like to
             browse our coaches and explore styles to find a coach you connect with."
            imageSrc="/images/My_account.svg"
            altText="By Coach"
            href={explore ? "/coaches?explore=true" : "/coaches"}
          />
          <ChoiceCard
            title="Choose by Time"
            description="Not sure what to talk about, or need to chat about something urgently? Add your preferences and book with a coach based on the topic and date and time that suits you."
            imageSrc="/images/Tools.svg"
            href={
              explore
                ? "/book-coaching/next-available-coach?explore=true"
                : "/book-coaching/next-available-coach"
            }
            altText="One Session"
          />
        </div>
      </div>
    </Layout>
  );
};
