import { Button } from "$/components/Button";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGetProgrammeIntrosQuery } from "$/graphql/storyblok/types.generated";
import { shuffle } from "lodash";
import { ProgrammeInformation } from "$/pages/Onboarding/components/CoachingDiagnosisQuiz";

const ButtonIntro = ({ setProgramme, send, programmes }) => {
  const newProgrammeList = [...programmes.list];
  const shuffledProgrammes = shuffle(newProgrammeList);

  return (
    <>
      <h1 className="text-center text-2xl md:text-4xl font-semibold mb-9">{programmes.title}</h1>
      <p className="text-center mb-14 text-lg">{programmes.subtitle}</p>

      <div className="w-full flex flex-col gap-4 justify-between">
        {shuffledProgrammes.map((prog) => (
          <Button
            key={prog.path}
            primary
            large
            onClick={() => {
              setProgramme(prog.path);
              send({ next: "why", value: prog.path });
            }}
            className="w-full"
          >
            {prog.text}
          </Button>
        ))}
      </div>
    </>
  );
};

const SingleIntro = ({ title, subtitle, path, setProgramme, send }) => {
  return (
    <>
      <div className="relative flex flex-col bg-white md:p-4 rounded-xl w-full pb-4">
        <div className="flex flex-col text-center justify-center pt-4">
          <h2 className="text-3xl">{title}</h2>
          <h2 className="text-2xl">How our Coaching Programmes work:</h2>
        </div>

        <div className="w-full flex flex-col justify-between p-4 gap-4 md:flex-row">
          <div className="rounded-2xl bg-beige p-4 text-center flex flex-col items-center mx-auto w-full max-w-[600px] gap-4">
            <div className="bg-yellow rounded-full h-9 w-9 flex items-center justify-center text-xl">
              1
            </div>
            <h2 className="max-w-[500px] text-xl mb-0">Tell us about you</h2>
            <p className="max-w-[500px] text-lg mb-0">
              You&apos;ll start by completing a short quiz setting you up with the programme
              specifically designed to foster your growth, confidence and skills around your chosen
              topic.
            </p>
          </div>
          <div className="rounded-2xl bg-beige p-4 text-center flex flex-col items-center mx-auto w-full max-w-[600px] gap-4">
            <div className="bg-yellow rounded-full h-9 w-9 flex items-center justify-center text-xl">
              2
            </div>
            <h2 className="max-w-[500px] text-xl mb-0">Find the perfect coach</h2>
            <p className="max-w-[500px] text-lg mb-0">
              Based on your responses, you&apos;ll choose a coach from your top matches, or browse
              our full list of coaches. You&apos;ll have the same coach throughout, with the option
              to swap coach after your first session.
            </p>
          </div>
          <div className="rounded-2xl bg-beige p-4 text-center flex flex-col items-center mx-auto w-full max-w-[600px] gap-4">
            <div className="bg-yellow rounded-full h-9 w-9 flex items-center justify-center text-xl">
              3
            </div>
            <h2 className="max-w-[500px] text-xl mb-0">Start your programme</h2>
            <p className="max-w-[500px] text-lg mb-0">
              Book your preferred coach and time instantly. Your coach and you will discuss your
              needs during your programme, and adapt as needed &ndash; supporting you at every step.
            </p>
          </div>
        </div>
      </div>
      <Button
        primary
        large
        className="w-full mt-2"
        onClick={() => {
          setProgramme(path);
          send({ next: "why", value: path });
        }}
      >
        Continue
      </Button>
    </>
  );
};

const EnquiryWelcome = ({ programmes, setWelcome }) => {
  return (
    <>
      <div className="relative flex flex-col bg-white md:p-4 rounded-xl w-full pb-4">
        <div className="flex flex-col text-center justify-center pt-4">
          <h2 className="text-3xl">{programmes.title}</h2>
        </div>

        <div className="w-full flex flex-col justify-between p-4 gap-4 md:flex-row">
          <div className="rounded-2xl bg-beige p-4 text-center flex flex-col items-center mx-auto w-full max-w-[600px] gap-4">
            <div className="bg-yellow rounded-full h-9 w-9 flex items-center justify-center text-xl">
              1
            </div>
            <h2 className="max-w-[500px] text-xl mb-0">Tell us about you</h2>
            <p className="max-w-[500px] text-lg mb-0">
              You&apos;ll start by completing a short quiz setting you up with the programme
              specifically designed to foster your growth, confidence and skills around your chosen
              topic.
            </p>
          </div>
          <div className="rounded-2xl bg-beige p-4 text-center flex flex-col items-center mx-auto w-full max-w-[600px] gap-4">
            <div className="bg-yellow rounded-full h-9 w-9 flex items-center justify-center text-xl">
              2
            </div>
            <h2 className="max-w-[500px] text-xl mb-0">Find the perfect coach</h2>
            <p className="max-w-[500px] text-lg mb-0">
              Based on your responses, you&apos;ll choose a coach from your top matches, or browse
              our full list of coaches. You&apos;ll have the same coach throughout, with the option
              to swap coach after your first session.
            </p>
          </div>
          <div className="rounded-2xl bg-beige p-4 text-center flex flex-col items-center mx-auto w-full max-w-[600px] gap-4">
            <div className="bg-yellow rounded-full h-9 w-9 flex items-center justify-center text-xl">
              3
            </div>
            <h2 className="max-w-[500px] text-xl mb-0">Start your programme</h2>
            <p className="max-w-[500px] text-lg mb-0">
              Book your preferred coach and time instantly. Your coach and you will discuss your
              needs during your programme, and adapt as needed &ndash; supporting you at every step.
            </p>
          </div>
        </div>
      </div>
      <Button
        primary
        large
        className="w-full mt-2"
        onClick={() => {
          setWelcome(false);
        }}
      >
        Continue
      </Button>
    </>
  );
};

export default function Intro({ setProgramme, send, title, subtitle }) {
  const [searchParams] = useSearchParams();
  const [intro, setIntro] = useState(null);
  const [welcome, setWelcome] = useState(true);
  const p = searchParams.get("i") ?? "default";
  const programmeIntro = useGetProgrammeIntrosQuery({ id: `programme-intros/${p}` });

  useEffect(() => {
    if (programmeIntro.isSuccess) {
      const { content } = programmeIntro.data.ProgrammeItem;
      setIntro({
        title: content.title,
        subtitle: content.subtitle,
        list: content.list.map((listItem) => ({
          text: listItem.text,
          path: listItem.quiz.cached_url.split("/")[1],
        })),
      });
    }
  }, [programmeIntro.isSuccess]);

  if (programmeIntro.isLoading) {
    return <div />;
  }

  return (
    <>
      {searchParams.get("p") && searchParams.get("r") ? (
        <>
          <ProgrammeInformation />
          <Button
            primary
            large
            className="w-full mt-2"
            onClick={() => {
              setProgramme(searchParams.get("p"));
              send({ next: "programme", value: searchParams.get("p"), why: "development-quiz" });
            }}
          >
            Continue
          </Button>
        </>
      ) : searchParams.get("p") ? (
        <SingleIntro
          title={title}
          subtitle={subtitle}
          path={searchParams.get("p")}
          send={send}
          setProgramme={setProgramme}
        />
      ) : welcome ? (
        <>{intro && <EnquiryWelcome programmes={intro} setWelcome={setWelcome} />}</>
      ) : (
        <>{intro && <ButtonIntro setProgramme={setProgramme} send={send} programmes={intro} />}</>
      )}
    </>
  );
}
