import {
  addHours,
  addMinutes,
  addMonths,
  differenceInHours,
  formatISO,
  startOfHour,
} from "date-fns";

export const createFullAvailability = () => {
  const timeSlotsforThreeMonths = [
    {
      startsAt: addHours(startOfHour(new Date()), 1),
      endsAt: addMonths(startOfHour(new Date()), 3),
    },
  ];

  const formattedEvents = timeSlotsforThreeMonths.flatMap((e) => {
    const diff = differenceInHours(e.endsAt, e.startsAt);
    const _event = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < diff; i++) {
      _event.push({
        ...e,
        startsAt: addHours(e.startsAt, i),
        endsAt: addHours(e.startsAt, i + 1),
      });
    }
    return _event;
  });

  return formattedEvents
    .filter(
      (e) =>
        differenceInHours(
          addMinutes(e.startsAt, e.startsAt.getTimezoneOffset()),
          addMinutes(new Date(), new Date().getTimezoneOffset()),
        ) >= 0,
    )
    .sort((a, b) => a.startsAt.getTime() - b.startsAt.getTime())
    .map((slot) => ({
      ...slot,
      startsAt: formatISO(slot.startsAt),
      endsAt: formatISO(slot.endsAt),
    }));
};
