import { Button } from "$/components/Button";
import { useEffect, useState } from "react";
import { Check } from "lucide-react";
import { DiagnosisQuizScoring } from "./DiagnosisQuizScoring";
import { diagnosisCategories } from "./diagnosisStates";
import { useNavigate } from "react-router";
import { useUser } from "$/state/user";
import {
  ListDiagnosisQuizSessionsQuery,
  OrderByArg,
  useCreateDiagnosisQuizSessionMutation,
  useGetDiagnosisQuizQuestionQuery,
  useListDiagnosisQuizSessionsQuery,
  useUpsertDiagnosisQuizResponseMutation,
} from "$/graphql/types.generated";
import { DiagnosisQuizProgressBar } from "./DiagnosisQuizProgressBar";
import { v4 as uuid } from "uuid";
import dayjs from "dayjs";

function DiagnosisQuizTable({
  previousQuizSessions,
}: {
  previousQuizSessions: ListDiagnosisQuizSessionsQuery["listDiagnosisQuizSessions"];
}) {
  const navigate = useNavigate();

  return (
    <>
      <div className="w-full flex flex-col justify-around mt-6 pb-6">
        <div className="w-full flex items-center justify-between">
          <h3 className="flex justify-center flex-grow font-semibold text-2xl">
            Your previous completed quizzes
          </h3>
        </div>
        <div className="flex justify-center max-h-[320px] overflow-auto">
          <table className="lg:min-w-[500px] xdivide-y divide-gray-200">
            <thead className="bg-gray-50 sticky top-0 z-10">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Date Taken
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  View Results
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {previousQuizSessions
                .filter((session) => session.responses.length >= 15)
                .map((session) => (
                  <tr key={session.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {dayjs(session.createdAt).format("DD MMMM YY")}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <Button
                        primary
                        large
                        onClick={() => {
                          navigate(`/360-quiz/results?qid=${session.id}`);
                        }}
                        className="w-fit mx-auto"
                      >
                        View results
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

function IntroPage({ onNext }: { onNext: (value: boolean) => void }) {
  const user = useUser();
  const [showTable, setShowTable] = useState(false);

  const { data: previousSessions, isLoading } = useListDiagnosisQuizSessionsQuery({
    where: {
      teamMemberId: { equals: user.currentUser?.teamMember?.id },
    },
    orderBy: [{ createdAt: OrderByArg.Desc }],
  });

  const completedQuizzes = previousSessions?.listDiagnosisQuizSessions?.filter(
    (session) => session.responses.length >= 15,
  );

  return (
    <>
      <div className="relative flex flex-col bg-white p-4 rounded-xl w-full pb-4">
        <div className="flex flex-col text-center justify-center pt-4">
          <h2 className="text-3xl">Unsure on what to take to your coach?</h2>
        </div>

        <div className="w-full flex flex-col justify-between p-4 gap-4 md:flex-row">
          <div className="rounded-2xl bg-beige p-4 text-center flex flex-col items-center mx-auto w-full max-w-[600px] gap-4">
            <div className="bg-yellow rounded-full h-9 w-9 flex items-center justify-center text-xl">
              1
            </div>
            <h2 className="max-w-[500px] text-xl mb-0">
              Answer our 2 minute quiz about where you are
            </h2>
            <p className="max-w-[500px] text-lg mb-0">
              Rate key aspects of your current state to identify where coaching can most benefit you
              right now.
            </p>
          </div>
          <div className="rounded-2xl bg-beige p-4 text-center flex flex-col items-center mx-auto w-full max-w-[600px] gap-4">
            <div className="bg-yellow rounded-full h-9 w-9 flex items-center justify-center text-xl">
              2
            </div>
            <h2 className="max-w-[500px] text-xl mb-0">Discover your growth opportunities</h2>
            <p className="max-w-[500px] text-lg mb-0">
              Recieve a personalised summary of your results, highlighting areas needing the most
              attention.
            </p>
          </div>
          <div className="rounded-2xl bg-beige p-4 text-center flex flex-col items-center mx-auto w-full max-w-[600px] gap-4">
            <div className="bg-yellow rounded-full h-9 w-9 flex items-center justify-center text-xl">
              3
            </div>
            <h2 className="max-w-[500px] text-xl mb-0">
              Work on a chosen growth area with a coach
            </h2>
            <p className="max-w-[500px] text-lg mb-0">
              Book a programme with a coach to work on your chosen focus area.
            </p>
          </div>
        </div>
        <p className="w-full text-center mb-0">
          Your responses are completely confidential. This is your safe space to discover meaningful
          areas for development in your life.
        </p>
      </div>
      <Button primary large className="w-full mt-2" onClick={() => onNext(true)}>
        Get started
      </Button>
      {!isLoading && completedQuizzes.length > 0 && (
        <Button tertiary large className="w-full mt-2" onClick={(curr) => setShowTable(!showTable)}>
          {!showTable ? "View previous quiz results" : "Hide previous quiz results"}
        </Button>
      )}
      {!isLoading && showTable && completedQuizzes.length > 0 && (
        <DiagnosisQuizTable previousQuizSessions={previousSessions.listDiagnosisQuizSessions} />
      )}
    </>
  );
}

export function ProgrammeInformation() {
  return (
    <>
      <div className="relative flex flex-col bg-white md:p-4 rounded-xl w-full pb-4">
        <div className="flex flex-col text-center justify-center pt-4">
          <h2 className="text-2xl">How our Coaching Programmes work:</h2>
          <h3>
            Engage in series of 3 or 6 regular 45 minute sessions with a Coach, focusing on a
            specific goal or challenge.
          </h3>
        </div>

        <div className="w-full flex flex-col justify-between p-4 gap-4 md:flex-row">
          <div className="rounded-2xl bg-beige p-4 text-center flex flex-col items-center mx-auto w-full max-w-[600px] gap-4">
            <div className="bg-lightGreen rounded-full h-9 w-9 flex items-center justify-center text-xl">
              <Check />
            </div>
            <h2 className="max-w-[500px] text-xl mb-0">Tell us about you</h2>
            <p className="max-w-[500px] text-lg mb-0">
              You&apos;ve taken the first step by finding an area to work on using our diagnosis
              tool.
            </p>
          </div>
          <div className="rounded-2xl bg-beige p-4 text-center flex flex-col items-center mx-auto w-full max-w-[600px] gap-4">
            <div className="bg-yellow rounded-full h-9 w-9 flex items-center justify-center text-xl">
              2
            </div>
            <h2 className="max-w-[500px] text-xl mb-0">Find the perfect coach</h2>
            <p className="max-w-[500px] text-lg mb-0">
              Tell us your availability and choose a coach from your top matches, or browse our full
              list of coaches.
            </p>
          </div>
          <div className="rounded-2xl bg-beige p-4 text-center flex flex-col items-center mx-auto w-full max-w-[600px] gap-4">
            <div className="bg-yellow rounded-full h-9 w-9 flex items-center justify-center text-xl">
              3
            </div>
            <h2 className="max-w-[500px] text-xl mb-0">Start your programme</h2>
            <p className="max-w-[500px] text-lg mb-0">
              Book your preferred coach and time instantly. Your coach will discuss your goals and
              needs during your first session.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

function ScoringPage({
  onNext,
  submitting,
  categoryText,
  questionId,
  categoryProgress,
  questionProgress,
}: {
  onNext: (value: number) => void;
  submitting: boolean;
  categoryText: string;
  questionId: string;
  categoryProgress: number;
  questionProgress: number;
}) {
  const [score, setScore] = useState<number | undefined>();
  const [dirty, setDirty] = useState(false);

  const { data: question, isFetching } = useGetDiagnosisQuizQuestionQuery({
    where: { id: questionId },
  });

  useEffect(() => {
    setScore(undefined); // Reset score when questionText changes
    setDirty(false);
  }, [questionId]);

  if (isFetching) {
    return (
      <>
        <DiagnosisQuizProgressBar
          categoryProgress={categoryProgress}
          questionProgress={questionProgress}
        />
        <div className="w-full relative flex flex-col text-center h-96 bg-white rounded-xl p-4 mt-2" />
      </>
    );
  } else
    return (
      <>
        <DiagnosisQuizProgressBar
          categoryProgress={categoryProgress}
          questionProgress={questionProgress}
        />
        <div className="w-full relative flex flex-col text-center gap-y-8 bg-white rounded-xl p-4 mt-2">
          <div>
            <h2 className="text-center text-xl font-semibold my-4">{categoryText}</h2>
            <h1 className="h-16">{question?.getDiagnosisQuizQuestion?.text}</h1>
            <p>Click on the dots to select a rating.</p>
          </div>
          <div className="w-[95%] md:w-[90%] mx-auto">
            <DiagnosisQuizScoring
              onChange={setScore}
              selected={score}
              setSelected={setScore}
              dirty={dirty}
              setDirty={setDirty}
            />
          </div>
          <Button
            primary
            large
            onClick={() => {
              onNext(score);
              setScore(undefined);
              setDirty(false);
            }}
            disabled={score === undefined}
            className="w-fit mx-auto"
            isSubmitting={submitting}
          >
            Submit
          </Button>
        </div>
      </>
    );
}

export function CoachingDiagnosisQuiz() {
  const [diagnosisQuizId, setDiagnosisQuizId] = useState<string | null>(null);
  const [page, setPage] = useState("introPage");
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const categoryEntries = Object.entries(diagnosisCategories);
  const [categoryKey, category] = categoryEntries[currentCategoryIndex] as [
    string,
    { title: string; questions: string[] },
  ];
  const user = useUser();
  const navigate = useNavigate();
  const createQuizSession = useCreateDiagnosisQuizSessionMutation();
  const upsertDiagnosisQuizResponse = useUpsertDiagnosisQuizResponseMutation();

  const handleOk = async () => {
    const diagnosisQuiz = await createQuizSession.mutateAsync({
      data: {
        teamMember: user.currentUser?.teamMember.id
          ? { connect: { id: user.currentUser.teamMember.id } }
          : undefined,
      },
    });
    setDiagnosisQuizId(diagnosisQuiz.createDiagnosisQuizSession.id);
    setPage("scoring");
  };

  const send = async ({ value }: { value: number }) => {
    if (page === "scoring") {
      const updateDiagnosisQuizResponse = await upsertDiagnosisQuizResponse.mutateAsync({
        create: {
          quizSession: { connect: { id: diagnosisQuizId } },
          question: { connect: { id: category.questions[currentQuestionIndex] } },
          score: value,
        },
        update: {
          quizSession: { connect: { id: diagnosisQuizId } },
          question: { connect: { id: category.questions[currentQuestionIndex] } },
          score: value,
        },
        where: {
          id: uuid(),
        },
      });
    }

    if (currentQuestionIndex < category.questions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
    } else if (currentCategoryIndex < categoryEntries.length - 1) {
      setCurrentCategoryIndex((prev) => prev + 1);
      setCurrentQuestionIndex(0);
    } else if (currentCategoryIndex === categoryEntries.length - 1) {
      navigate(`/360-quiz/results?qid=${diagnosisQuizId}`);
    }
  };

  return (
    <>
      <div className="max-w-4xl mx-auto pt-8 pb-0 flex flex-col justify-between items-between">
        {page === "introPage" && <IntroPage onNext={handleOk} />}
        {page === "scoring" && category && (
          <div key={categoryKey} className="mb-8">
            <div className="mb-4">
              <ScoringPage
                questionId={category.questions[currentQuestionIndex]}
                categoryText={category.title}
                submitting={false}
                onNext={(value) => send({ value })}
                categoryProgress={currentCategoryIndex}
                questionProgress={currentQuestionIndex}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
