import { useState } from "react";
import { Auth } from "@aws-amplify/auth";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { Button, Form, Image, Input, Layout, Space, Typography } from "antd";

import { HtmlMeta } from "$/components/HtmlMeta";
import { Message } from "$/components/Message";

import { DATA_TEST_IDS } from "$/elements.config";

import { WEB_ASSETS_URL } from "$/configs/app.config";

import { Mixpanel } from "$/tracking";

type FormInputs = {
  email: string;
};

export const ResetPasswordPage = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [searchParams] = useSearchParams();

  const returnUrlParams = searchParams.get("returnUrl");
  const returnUrl = decodeURIComponent(returnUrlParams);

  const defaultValues = {
    email: searchParams.get("email") || "",
  };

  const onSubmit = async (values: FormInputs) => {
    try {
      setLoading(true);

      const email = values.email?.trim()?.toLowerCase();

      await Auth.forgotPassword(email);

      Mixpanel.track("Password Reset Requested");

      setLoading(false);

      navigate(`/auth/confirm-password-reset?email=${encodeURIComponent(email)}`);
    } catch (err: any) {
      setError(err.message);
      return setLoading(false);
    }
  };

  const onSubmitFailed = (err: any) => setError(err.message);

  return (
    <Layout className="mh-layout-public">
      <HtmlMeta title="Reset Password" />

      <Layout.Content>
        <div className="site-login-content">
          <div className="login-wrap">
            <Link to="/auth/login">
              <Image
                className="login-img"
                preview={false}
                src={`${WEB_ASSETS_URL}/More_Happi_logo.svg`}
                alt="logo"
              />
            </Link>

            <Space direction="vertical" size={32}>
              <Typography.Title level={1}>Reset your password</Typography.Title>

              <div className="auth-form-wrap">
                <Form
                  name="passwordReset"
                  initialValues={defaultValues}
                  layout="vertical"
                  onFinish={(values) => onSubmit(values)}
                  onFinishFailed={onSubmitFailed}
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "Please enter your email",
                      },
                    ]}
                  >
                    <Input size="large" data-cy={DATA_TEST_IDS.LOGIN.EMAIL.INPUT} />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      block
                      size="large"
                      type="primary"
                      htmlType="submit"
                      disabled={loading}
                      data-cy={DATA_TEST_IDS.LOGIN.SUBMIT.BUTTON}
                    >
                      {loading ? "Sending... " : "Send Instructions"}
                    </Button>
                  </Form.Item>
                </Form>

                {error && <Message type="error" items={[{ message: error }]} />}

                <Link to="/auth/login">Return to Sign In</Link>

                {returnUrlParams && (
                  <a
                    className="flex mt-4 justify-center underline cursor-pointer"
                    href={`/${returnUrl}`}
                  >
                    Return to your Programme Booking
                  </a>
                )}
              </div>
            </Space>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  );
};
