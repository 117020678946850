import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Label } from "recharts";

const pieChartColours = ["#006A39", "#F5C900", "#F34100", "#F7F7F7"];

export default function DiagnosisPieChart({
  header,
  percentage,
}: {
  header: string;
  percentage: number;
}) {
  const rounded = Math.round(percentage);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={200} height={200}>
        <Pie
          data={[
            { name: header, value: rounded },
            { name: header, value: 100 - rounded },
          ]}
          nameKey="name"
          dataKey="value"
          innerRadius="55%"
          outerRadius="80%"
          startAngle={90}
          endAngle={-270}
          fill="#8884d8"
        >
          <Cell
            key={`cell-${header}`}
            fill={
              percentage >= 89
                ? pieChartColours[0]
                : percentage >= 51
                ? pieChartColours[1]
                : pieChartColours[2]
            }
          />
          <Cell key={`cell-${header}2`} fill={pieChartColours[3]} />
          <Label width={60} position="center">
            {`${rounded}% ${header}`}
          </Label>
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}
