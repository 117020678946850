import { useIsMobile } from "$/hooks";
import { useUser } from "$/state/user";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { diagnosisCategories, getFeedback } from "./diagnosisStates";
import DiagnosisPieChart from "./DiagnosisPieChart";
import { Button } from "$/components/Button";
import { Mixpanel } from "$/tracking";
import { Info } from "lucide-react";
import { Modal } from "$/components/Modal";
import { ProgrammeInformation } from "./CoachingDiagnosisQuiz";
import { useSearchParams } from "react-router-dom";
import { useGetDiagnosisQuizSessionQuery } from "$/graphql/types.generated";

export function DiagnosisQuizResults() {
  const [searchParams] = useSearchParams();
  const [showMore, setShowMore] = useState(false);
  const [showProgrammeInfo, setShowProgrammeInfo] = useState(false);

  const user = useUser();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const getQuizSession = useGetDiagnosisQuizSessionQuery({
    where: { id: searchParams.get("qid") },
  });

  const answers = getQuizSession.isFetched
    ? getQuizSession?.data?.getDiagnosisQuizSession?.responses
    : [];

  const calculateCategoryScore = (givenCategory: string, responses: any[] = []) => {
    const category = diagnosisCategories[givenCategory];
    if (!category || !category.questions) {
      return 0;
    }

    const categoryAnswers = responses
      .filter((response) => category.questions.includes(response.question.id))
      .map((response) => response.score ?? 0);

    const total = categoryAnswers.reduce((sum, val) => sum + val, 0);
    return categoryAnswers.length > 0 ? (total / (categoryAnswers.length * 10)) * 100 : 0;
  };

  if (!getQuizSession?.isFetched) return <></>;

  return (
    <div className="max-w-4xl mx-auto md:pt-8 pb-0 flex flex-col justify-between items-between">
      <div className="bg-white rounded-lg shadow-lg mb-8">
        <div className="px-6 py-4 ">
          <h2 className="text-2xl font-bold text-center">Your development priority</h2>
          {Object.entries(diagnosisCategories)
            .sort(
              (a, b) =>
                calculateCategoryScore(a[0], answers) - calculateCategoryScore(b[0], answers),
            )
            .slice(0, 1)
            .map(([currentCategoryKey, currentCategory]) => {
              const score = calculateCategoryScore(currentCategoryKey, answers);
              const feedback = getFeedback(currentCategoryKey, score);
              return (
                <div key={currentCategoryKey} className="my-6 p-4 border rounded">
                  <div className="flex flex-col md:flex-row items-center justify-around gap-2">
                    <div className="flex flex-col w-full md:w-2/3">
                      <h3 className="text-lg font-semibold">
                        Based on your results, you main area of focus is:
                      </h3>
                      <h3 className="text-2xl font-semibold">{currentCategory.title}</h3>
                      <p className="mt-2 text-lg">{feedback.message}</p>
                    </div>
                    <div className="w-full md:w-1/2 flex self-end h-[200px]">
                      <DiagnosisPieChart
                        header={
                          score >= 89 ? "Excelling" : score >= 51 ? "Room to Grow" : "Needs Focus"
                        }
                        percentage={score}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row mt-4 bg-amber-100 p-4 rounded-lg items-center justify-between">
                    <p className="whitespace-pre-line text-lg pl-4">{feedback.actions}</p>
                    <p className="mt-4 italic md:w-1/3">{feedback.stats}</p>
                  </div>
                  <div className="w-full flex justify-center mt-6">
                    <Button
                      primary
                      large
                      onClick={() => {
                        Mixpanel.track(
                          `Diagnostic Tool: Book Programme (${currentCategory.title})`,
                          {
                            user: user?.currentUser?.id,
                          },
                        );
                        navigate(`/enquiry?p=${currentCategory.enquiry}&r=diagnosis`);
                      }}
                    >
                      {feedback.buttonText}
                    </Button>
                    <Info
                      color="darkGreen"
                      className="cursor-pointer mt-2 ml-4"
                      onClick={() => setShowProgrammeInfo(true)}
                    />
                  </div>
                </div>
              );
            })}

          {showProgrammeInfo && (
            <Modal
              onClose={() => {
                setShowProgrammeInfo(false);
              }}
              size={isMobile ? "lg" : "xl"}
            >
              <ProgrammeInformation />
            </Modal>
          )}

          <h2 className="text-2xl font-bold text-center">Other development opportunities</h2>
          <p className="text-lg text-center">
            Below is a summary of the rest of your results from the diagnosis quiz.
          </p>
          <div className="flex justify-around flex-wrap">
            {Object.entries(diagnosisCategories)
              .sort(
                (a, b) =>
                  calculateCategoryScore(a[0], answers) - calculateCategoryScore(b[0], answers),
              )
              .slice(1, Object.entries(diagnosisCategories).length)
              .map(([currentCategoryKey, currentCategory]) => {
                const score = calculateCategoryScore(currentCategoryKey, answers);
                return (
                  <div
                    key={currentCategoryKey}
                    className="flex flex-col w-1/2 md:w-1/4 items-center text-center justify-around gap-2"
                  >
                    <h3 className="h-8 text-lg font-semibold">{currentCategory.title}</h3>
                    <div className="flex w-[200px] h-[200px]">
                      <DiagnosisPieChart
                        header={
                          score >= 89 ? "Excelling" : score >= 51 ? "Room to Grow" : "Needs Focus"
                        }
                        percentage={score}
                      />
                    </div>
                  </div>
                );
              })}
          </div>

          <div className="w-full flex justify-center">
            <Button secondary large onClick={() => setShowMore(!showMore)} className="w-full mt-6">
              {showMore ? "Hide more results" : "Show more results"}
            </Button>
          </div>

          {showMore &&
            Object.entries(diagnosisCategories)
              .sort(
                (a, b) =>
                  calculateCategoryScore(a[0], answers) - calculateCategoryScore(b[0], answers),
              )
              .slice(1, Object.entries(diagnosisCategories).length)
              .map(([currentCategoryKey, currentCategory]) => {
                const score = calculateCategoryScore(currentCategoryKey, answers);
                const feedback = getFeedback(currentCategoryKey, score);
                return (
                  <div key={currentCategoryKey} className="my-6 p-4 border rounded">
                    <div className="flex flex-col md:flex-row items-center justify-around gap-2">
                      <div className="flex flex-col w-full md:w-2/3">
                        <h3 className="text-2xl font-semibold">{currentCategory.title}</h3>
                        <p className="mt-2 text-lg">{feedback.message}</p>
                      </div>
                      <div className="w-full md:w-1/2 flex self-end h-[200px]">
                        <DiagnosisPieChart
                          header={
                            score >= 89 ? "Excelling" : score >= 51 ? "Room to Grow" : "Needs Focus"
                          }
                          percentage={score}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col md:flex-row mt-4 bg-amber-100 p-4 rounded-lg items-center justify-between">
                      <p className="whitespace-pre-line text-lg pl-4">{feedback.actions}</p>
                      <p className="mt-4 italic md:w-1/3">{feedback.stats}</p>
                    </div>
                    <div className="w-full flex justify-center mt-6">
                      <Button
                        primary
                        large
                        onClick={() => {
                          Mixpanel.track(
                            `Diagnostic Tool: Book Programme (${currentCategory.title})`,
                            {
                              user: user?.currentUser?.id,
                            },
                          );
                          navigate(`/enquiry?p=${currentCategory.enquiry}&r=diagnosis`);
                        }}
                      >
                        {feedback.buttonText}
                      </Button>
                      <Info
                        color="darkGreen"
                        className="cursor-pointer mt-2 ml-4"
                        onClick={() => setShowProgrammeInfo(true)}
                      />
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
}
