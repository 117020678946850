import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams, useSearchParams } from "react-router-dom";
import { useScrollTo } from "$/hooks";
import { HtmlMeta } from "$/components/HtmlMeta";
import { LoadingSpinner } from "$/components/LoadingSpinner";
import { useUser } from "$/state/user";
import { IPageBaseProps } from "$/interfaces";
import cn from "classnames";
import {
  useCancelSessionMutation,
  useCoachAvailabilityQuery,
  useCreateSessionMutation,
  useGetCoachQuery,
  useGetSessionByIdQuery,
  useListCoachPackagesQuery,
} from "$/graphql/types.generated";
import { Mixpanel } from "$/tracking";
import { app } from "$/configs/app.config";
import { Button } from "$/components/Button";
import { AlertTriangle } from "lucide-react";
import { Email, MapPin } from "$/components/Icons";
import { Modal } from "$/components/Modal";
import { TagList, Tags, explorationSessionTags, initialTags } from "$/components/TopicSelector";
import * as jose from "jose";
import { DateTime } from "luxon";
import { UserDetailsModal } from "$/components/Modals/UserDetailsModal";
import { useCompany } from "$/state/company";
import { coachPackagesForCompanyType, orderCoachPackages } from "./helpers/coachPackagesForCompany";
import { SubscriptionPermission } from "$/components/SubscriptionPermission";

interface IProps extends IPageBaseProps {}

export const ConfirmBooking: React.FC<IProps> = () => {
  useScrollTo();
  const navigate = useNavigate();
  const { coachHandle } = useParams();
  const [searchQuery] = useSearchParams();
  const { date, time } = Object.fromEntries(searchQuery.entries());
  const day = DateTime.fromFormat(date, "yyyyMMdd").set({
    hour: Number(time),
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const [moreDetailsOpen, setMoreDetailsOpen] = useState(false);
  const [topicChooserOpen, setTopicChooserOpen] = useState(false);

  const t = searchQuery.get("t");
  const isExplorationSession = searchQuery.get("explore") === "true";
  const [selectedTags, setSelectedTags] = useState<Tags>(
    isExplorationSession ? explorationSessionTags : initialTags,
  );

  const user = useUser();
  const company = useCompany();
  const createSession = useCreateSessionMutation();
  const cancelSession = useCancelSessionMutation();

  const returnUrl = searchQuery.get("returnUrl");

  const getCoachQuery = useGetCoachQuery({
    where: { handle: coachHandle },
  });
  const coachAvailabilityQuery = useCoachAvailabilityQuery(
    {
      coachId: getCoachQuery.data?.getCoach.id,
      startsAt: day.startOf("day").toISO(),
      endsAt: day.endOf("day").toISO(),
    },
    { enabled: !!getCoachQuery.data?.getCoach.id },
  );

  const isSlotAvailable = coachAvailabilityQuery?.data?.coachAvailability?.find(
    (availability) => DateTime.fromISO(availability.startsAt).diff(day, "minutes").minutes === 0,
  );

  const coachPackages = useListCoachPackagesQuery({
    where: { id: { in: coachPackagesForCompanyType(company.currentCompany.accountType) } },
  });

  const rearragingSession = useGetSessionByIdQuery(
    {
      id: searchQuery.get("r")!,
    },
    { enabled: !!searchQuery.get("r") },
  );

  useEffect(() => {
    if (!coachHandle || getCoachQuery.error) {
      return navigate("/coaches");
    }

    if (getCoachQuery?.data?.getCoach) {
      Mixpanel.track("Coach Profile Viewed", {
        handle: coachHandle,
        loggedIn: !!user.currentUser,
      });
    }
  }, [coachHandle, getCoachQuery]);

  useEffect(() => {
    const getQueryTags = async () => {
      if (searchQuery.get("t")) {
        const { payload: queryTags } = await jose.jwtVerify(t!, new TextEncoder().encode("secret"));
        setSelectedTags(queryTags as Tags);
      }
    };
    getQueryTags();
  }, [searchQuery.get("t")]);

  const coach = getCoachQuery?.data?.getCoach;

  const submit = async () => {
    try {
      const session = await createSession.mutateAsync({
        input: {
          teamMemberId: user?.currentUser?.teamMember?.id!,
          coachId: coach?.id!,
          startsAt: day.toISO(),
          endsAt: day.plus({ hour: 1 }).toISO(),
          approaches: selectedTags.approaches,
          categories: selectedTags.topics,
          specialisms: selectedTags.specialities,
          audienceExperiences: selectedTags.experiences,
          packages: selectedTags.packages,
          packageId:
            rearragingSession?.data?.getSession?.userPackage?.id || searchQuery.get("package"),
        },
      });

      if (searchQuery.get("r")) {
        await cancelSession.mutateAsync({
          sessionId: searchQuery.get("r")!,
          reason: `Session was rescheduled to ${day.toFormat("dd/MM/yyyy HH:mm a")}`,
        });
      }

      if (returnUrl) {
        user.invalidate();
        setTimeout(() => {
          navigate(`/${returnUrl}`);
        }, 1000);
      } else {
        navigate(`/booking-confirmed?session=${session.createSessionWithTags?.id}`);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <SubscriptionPermission required>
      <div className="my-4">
        <HtmlMeta title="Coach" />
        <div className="mx-auto mb-20 max-w-[800px]">
          {getCoachQuery.isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <section className="mx-2 md:mx-0 mb-8 bg-white rounded-xl">
                <div className="flex flex-col items-center  p-6 w-full md:w-[70%] mx-auto text-center">
                  <div className="flex flex-col items-center justify-center mb-2">
                    <img
                      src={decodeURIComponent(
                        `${app.CLOUDFRONT.USER_ASSETS}/${coach.id}/thumbnail.jpg`,
                      )}
                      alt={coach?.displayName ?? "Profile photo"}
                      className="object-cover aspect-w-1 aspect-h-1 w-[86px] h-[86px] rounded-full"
                    />
                  </div>
                  <p className="text-lg">
                    {isExplorationSession ? "Exploration" : "Coaching"} session with{" "}
                    {coach?.displayName}
                  </p>
                  <h1 className="text-2xl md:text-4xl font-semibold ">{day.toFormat("DDDD")}</h1>
                  <h2 className="text-2xl md:text-4xl font-normal">
                    {day.toFormat("h a")} to {day.plus({ hour: 1 }).toFormat("h a")}
                  </h2>
                  <div className="flex flex-col md:flex-row gap-x-12 md:text-lg items-center">
                    <div className="flex items-center gap-x-1">
                      <MapPin fill="#006A39" size={12} /> Online via Google Meet
                    </div>
                    <div className="flex items-center gap-x-1">
                      <Email />
                      {user.currentUser?.email}
                    </div>
                  </div>
                  {rearragingSession.data && (
                    <div className="bg-pink px-4 py-2 mt-4 w-full  rounded-xl flex gap-x-2 items-center">
                      <AlertTriangle size="18px" />
                      You are rearranging your session from{" "}
                      {DateTime.fromISO(rearragingSession.data?.getSession?.startsAt).toFormat(
                        "dd/MM/yyyy HH:mm a",
                      )}{" "}
                    </div>
                  )}
                  {coachAvailabilityQuery.isFetched && !isSlotAvailable && (
                    <div className="bg-pink px-4 py-2 mt-4 w-full  rounded-xl flex gap-x-2 items-center">
                      <AlertTriangle size="18px" />
                      This timeslot is no longer available, please choose another date or time.
                    </div>
                  )}

                  {moreDetailsOpen && (
                    <UserDetailsModal
                      onClose={() => {
                        setMoreDetailsOpen(false);
                        if (searchQuery.get("skipTags") === "true" || searchQuery.get("package")) {
                          submit();
                        } else {
                          setTopicChooserOpen(true);
                        }
                      }}
                    />
                  )}

                  <div className="flex flex-col-reverse md:flex-row w-full gap-4 mt-8">
                    <div
                      className={cn({
                        "basis-[50%]": !coachAvailabilityQuery.isFetched || isSlotAvailable,
                        "w-full": !(!coachAvailabilityQuery.isFetched || isSlotAvailable),
                      })}
                    >
                      <Button
                        tertiary
                        large
                        className="w-full"
                        onClick={() => navigate(returnUrl ? `/${returnUrl}` : -1)}
                      >
                        Find another time
                      </Button>
                    </div>
                    {(!coachAvailabilityQuery.isFetched || isSlotAvailable) && (
                      <Button
                        primary
                        large
                        className="w-full"
                        onClick={() => {
                          if (!user.currentUser?.phone) {
                            setMoreDetailsOpen(true);
                          } else if (
                            searchQuery.get("skipTags") === "true" ||
                            searchQuery.get("package")
                          ) {
                            submit();
                          } else {
                            setTopicChooserOpen(true);
                          }
                        }}
                        disabled={coachAvailabilityQuery.isLoading}
                        isSubmitting={createSession.isPending || cancelSession.isPending}
                      >
                        Yes, book now
                      </Button>
                    )}
                  </div>
                </div>
              </section>

              <section className="w-full border border-skin border-solid p-9 rounded-sm">
                <div>
                  <div className="flex">
                    <img src="/images/Book_a_session.svg" alt="" className="h-20 w-20" />
                    <h2 className="border-0 border-l border-solid border-orange ml-4 pl-4 py-1">
                      About More <br /> Happi Coaches
                    </h2>
                  </div>
                  <div className="mt-4 mb-9 text-lg">
                    <p className="font-semibold">
                      If you need to rearrange for any reason, you will give{" "}
                      <u>at least 48 hrs notice</u> so that someone else might benefit from that
                      time.
                    </p>
                    <p>
                      More Happi enables volunteer coaches to build their experience and practice
                      that they need to become exceptional coaches at a rate that would be
                      impossible in other ways.
                    </p>
                    <p>
                      Coaching is not a substitute for counselling, therapy, mental health care or
                      substance abuse treatment.
                    </p>

                    <p>You take full responsibility for your decisions and mental wellbeing.</p>
                  </div>
                  <a
                    href="https://intercom.help/more-happi/en/collections/2828356-faqs"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button large secondary className="border-bottleGreen text-bottleGreen">
                      Visit our FAQs for more support
                    </Button>
                  </a>
                </div>
              </section>
            </>
          )}
          {topicChooserOpen && (
            <Modal onClose={() => setTopicChooserOpen(false)} size="lg">
              <div className=" text-center">
                <h2>What will your session be about?</h2>

                {coachPackages?.data?.listCoachPackages.length > 0 && (
                  <div className="w-fit mx-auto my-9">
                    <TagList
                      id="packages"
                      list={orderCoachPackages(
                        coachPackages?.data?.listCoachPackages,
                        company.currentCompany.accountType,
                      )}
                      selected={selectedTags.packages}
                      onSelect={(newTags) => setSelectedTags(newTags)}
                      key="packages"
                    />
                  </div>
                )}

                <div className="w-full flex mt-8 justify-between gap-x-8">
                  <Button
                    className="bg-bottleGreen border-bottleGreen border-solid text-white w-full"
                    large
                    onClick={submit}
                    isSubmitting={createSession.isPending || cancelSession.isPending}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Modal>
          )}
        </div>
      </div>
    </SubscriptionPermission>
  );
};
