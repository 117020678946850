import { ListSessionsQuery, SessionState } from "$/graphql/types.generated";
import dayjs from "dayjs";
import { useState } from "react";
import ExportCSV from "./ExportCSV";
import { useIsMobile } from "$/hooks";

interface TeamMemberData {
  teamMemberId: string;
  name: string;
  isActive: boolean;
  sessionsBooked: number;
  sessionsCancelled: number;
  sessionsRescheduled: number;
  noShows: number;
  sessionsCompleted: number;
  sessionsUpcoming: number;
  lastBooking: string;
}

type GroupedData = Record<string, TeamMemberData>;

export default function UsersStats({
  sessionsList,
}: {
  sessionsList: ListSessionsQuery["listSessions"];
}) {
  const isMobile = useIsMobile(500);
  const [showTable, setShowTable] = useState(true);

  const groupedData = sessionsList.reduce<GroupedData>((acc, current) => {
    const teamMemberId = current.teamMember.id;

    if (!acc[teamMemberId]) {
      acc[teamMemberId] = {
        teamMemberId,
        name: `${current.teamMember.user.firstName} ${current.teamMember.user.lastName}`,
        isActive: current.teamMember.user.isActive,
        sessionsBooked: 0,
        sessionsRescheduled: 0,
        sessionsCancelled: 0,
        noShows: 0,
        sessionsCompleted: 0,
        sessionsUpcoming: 0,
        lastBooking: current.startsAt,
      };
    }
    acc[teamMemberId].sessionsBooked += 1;

    if (current.state === SessionState.Cancelled) {
      if (current?.cancellationReason?.startsWith("Session was rescheduled")) {
        acc[teamMemberId].sessionsRescheduled += 1;
      } else acc[teamMemberId].sessionsCancelled += 1;
    }
    if (current.noShow) {
      acc[teamMemberId].noShows += 1;
    }
    if (
      dayjs(current.startsAt).isBefore(dayjs()) &&
      (current.state === SessionState.Confirmed ||
        current.state === SessionState.ConfirmedCoach ||
        current.state === SessionState.Completed)
    ) {
      acc[teamMemberId].sessionsCompleted += 1;
    }

    if (dayjs(current.startsAt) > dayjs() && current.state === SessionState.Confirmed) {
      acc[teamMemberId].sessionsUpcoming += 1;
    }
    if (
      dayjs(current.startsAt) > dayjs(acc[teamMemberId].lastBooking) &&
      (current.state === SessionState.Confirmed ||
        current.state === SessionState.ConfirmedCoach ||
        current.state === SessionState.Completed)
    ) {
      acc[teamMemberId].lastBooking = current.startsAt;
    }

    return acc;
  }, {});

  const sessionsByUser = Object.values(groupedData).sort(
    (a, b) => dayjs(b.lastBooking).valueOf() - dayjs(a.lastBooking).valueOf(),
  );

  const exportData = sessionsByUser.map((user: TeamMemberData) => ({
    name: user.name,
    Active: user.isActive ? "Yes" : "No",
    Bookings: user.sessionsBooked,
    Completed: user.sessionsCompleted,
    Rescheduled: user.sessionsRescheduled,
    Cancelled: user.sessionsCancelled,
    NoShows: user.noShows,
    Upcoming: user.sessionsUpcoming,
    LastBooking: dayjs(user.lastBooking).format("MMMM YY"),
  }));

  return (
    <>
      <div className="w-full flex flex-col justify-around pt-6 pb-6">
        <div className="w-full flex items-center justify-between">
          {!isMobile && (
            <div className="flex-grow ml-auto">
              <div className="max-w-20 cursor-pointer" onClick={() => setShowTable(!showTable)}>
                {showTable ? "Hide" : "Show"} fields
              </div>
            </div>
          )}
          <h3 className="flex justify-center flex-grow font-semibold text-2xl">Sessions by user</h3>
          <div className="flex flex-grow items-end justify-end">
            <ExportCSV data={exportData} filename="More-Happi-User-Data.csv" />
          </div>
        </div>
        {isMobile ? (
          <div className="w-full text-center">
            <p>This table is not shown on mobile but can be exported as normal</p>
          </div>
        ) : (
          <div className="max-h-[560px] overflow-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 sticky top-0 z-10">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    User
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Active
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Last Booking
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Bookings
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Completed
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Rescheduled
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Cancelled
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    No Shows
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Upcoming
                  </th>
                </tr>
              </thead>
              {showTable && (
                <tbody className="bg-white divide-y divide-gray-200">
                  {exportData.map((user) => (
                    <tr key={user.name}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {user.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.Active}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.LastBooking}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.Bookings}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.Completed}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.Rescheduled}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.Cancelled}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.NoShows}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.Upcoming}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        )}
      </div>
    </>
  );
}
