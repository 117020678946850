export const diagnosisCategories = {
  careerProgression: {
    title: "Career progression and performance",
    enquiry: "career-development",
    questions: [
      "3887c232-28ff-4bc8-9fc2-ac22ec94bb8a",
      "6e339055-9681-40ff-acf7-b8ee231bfa43",
      "4ca79c0e-b367-4659-8c27-7ad5f705c113",
    ],
  },
  communicationSkills: {
    title: "Communication skills",
    enquiry: "communication-skills",
    questions: [
      "9bfeecd7-5650-421e-bda8-118f2d384bd9",
      "36d5df9c-bc4c-4b0a-bda8-e09800a5d9d3",
      "8b4efaf0-0179-413b-a95d-d54c770973a0",
    ],
  },
  workLifeBalance: {
    title: "Work / Life balance",
    enquiry: "control",
    questions: [
      "d02859d8-e35d-4850-a33f-acb6133b1040",
      "4db49b0d-6a6d-42c1-b764-2880c2c3e88f",
      "296797f3-4e68-4bf4-a8ed-296a3cf377d4",
    ],
  },
  clearGoals: {
    title: "Clear goals",
    enquiry: "create-goals",
    questions: [
      "f2cb3d4d-ce32-4b9d-b079-e6d2f28ab889",
      "9011f2b2-3a28-4b2b-8d56-f99b5b777f2c",
      "54374e3c-9acf-4db3-a60e-bd2a40a0bdde",
    ],
  },
  relationships: {
    title: "Relationships and conflict resolution",
    enquiry: "relationship",
    questions: [
      "5f62b849-a9df-4ef1-b0e4-84fbd30fee4a",
      "3333fd7b-9d01-4456-a2d5-69d60aa2a625",
      "8993e28e-f4f7-45ec-8edb-28b4c3266bb6",
    ],
  },
};

export const getFeedback = (category, score) => {
  const feedback = {
    careerProgression: {
      high: {
        message:
          "While you're successfully navigating your career, as you develop into new roles and take on new challenges, coaching can help you to truly unlock your full potential.",
        actions:
          "A coach can help you:\n• Move from tactical to strategic roles\n• Build high-level stakeholder relationships\n• Shape organisational direction\n• Develop your authentic style of leadership\n• Create exceptional performance from those around you",
        testimonial:
          "I was hitting all my targets but knew there was another level. My coach helped me shift from delivering results to shaping strategy. Now I'm influencing decisions at the executive level and leading our most critical growth initiative.",
        attribution: "Director of Operations, Tech Scale-up",
        stats:
          "62% of coaching clients report that coaching improved their career opportunities, highlighting its effectiveness in helping individuals advance professionally. (Source: ICF Global Coaching Study)",
        buttonText: "Join our Career Progression Programme",
        cta: "You're already on the right path! Take your leadership to the next level with expert coaching.",
      },
      medium: {
        message:
          "You're delivering results but coaching can help you to move from good to great. Coaches can help you to identify the direction that you want to go, and how to create a plan to get you there in a way that's authentic and works for you.",
        actions:
          "A coach can help you:\n• Understand what really drives your advancement\n• Develop in the areas that matter to you and your business\n• Increase your visibility and have more impact\n• Position yourself for future opportunities\n• Build consistent high performance",
        testimonial:
          "I was putting in the hours, getting good feedback, but watching others zoom past me. My coach helped me realise I was hoping my work would speak for itself. I've changed how I do things and have been offered a new role as a result.",
        attribution: "Engineering Lead, AI Scale-up",
        stats:
          "62% of coaching clients report that coaching improved their career opportunities, highlighting its effectiveness in helping individuals advance professionally. (Source: ICF Global Coaching Study)",
        buttonText: "Join our Career Progression Programme",
        cta: "You're making good progress! Coaching can help you sharpen your skills and accelerate your career growth.",
      },
      low: {
        message:
          "Feeling uncertain about your career direction can impact everything from daily motivation to long-term decision making.",
        actions:
          "A coach can help you:\n• Discover what really drives you\n• Map out possible career paths\n• Build confidence in your choices\n• Create an action plan that motivates you\n• Develop performance strategies that work for you",
        testimonial:
          "Was totally lost career-wise - taking on random projects, hoping something would click. My coach helped me get clear on what I actually wanted. Now I've got a real plan I'm excited about.",
        attribution: "Product Manager, Tech Start-up",
        stats:
          "62% of coaching clients report that coaching improved their career opportunities, highlighting its effectiveness in helping individuals advance professionally. (Source: ICF Global Coaching Study)",
        buttonText: "Join our Career Progression Programme",
        cta: "Not sure what's next? A coach can help you gain clarity and build a career path that excites you.",
      },
    },

    communicationSkills: {
      high: {
        message:
          "Your communication skills are strong, but as you step into more senior roles, the stakes and complexity increase. Coaching can help you develop a leadership presence that matches your expertise.",
        actions:
          "A coach can help you:\n• Master high-stakes communications\n• Develop authentic leadership presence\n• Lead through uncertainty and change\n• Shape organisational narratives\n• Influence at a strategic level",
        testimonial:
          "Used to think being articulate was enough. My coach helped me see that true leadership presence goes far deeper. Now I'm not just communicating clearly - I'm shaping how people think about our entire industry. Recently led our biggest strategic pivot, and the board presentation felt natural.",
        attribution: "Director of Strategy, Global Tech Company",
        stats:
          "Coaching has been shown to result in a 173% improvement in interpersonal skills, which are essential for building impactful relationships with stakeholders and enhancing influence in the workplace. (Source: Sounding Board Inc.)",
        buttonText: "Join our Communication Skills Programme",
        cta: "You're already a strong communicator! Coaching can help you refine your leadership presence and strategic influence.",
      },
      medium: {
        message:
          "Your ideas have merit, but they might not be getting the attention and impact they deserve. Coaching can help you develop the confidence and skills to ensure your voice is heard.",
        actions:
          "A coach can help you:\n• Find your authentic voice\n• Structure your message for impact\n• Handle challenging situations\n• Build confidence in your presentation skills\n• Increase your influence",
        testimonial:
          "Got frustrated watching others with less experience but more confidence get ahead. My coach helped me understand what was missing in how I communicated. Started small - speaking up in team meetings, then client presentations. Now leading our pitch process. The confidence shift has been unreal.",
        attribution: "Senior Product Manager, E-commerce Scale-up",
        stats:
          "Coaching has been shown to result in a 173% improvement in interpersonal skills, which are essential for building impactful relationships with stakeholders and enhancing influence in the workplace. (Source: Sounding Board Inc.)",
        buttonText: "Join our Communication Skills Programme",
        cta: "You're making progress! Coaching can help you refine your communication skills and boost your confidence.",
      },
      low: {
        message:
          "Communication challenges can make every interaction feel like a struggle, leading to stress and missed opportunities to contribute your valuable ideas.",
        actions:
          "A coach can help you:\n• Build core confidence\n• Develop key communication skills\n• Overcome presentation anxiety\n• Structure your thoughts clearly\n• Handle difficult conversations",
        testimonial:
          "I used to avoid any kind of presentation or confrontation. Even team meetings were stressful. My coach helped me understand where this was coming from and build real confidence. Last month I actually volunteered to present at our company all-hands. Still can't quite believe that was me up there.",
        attribution: "Data Scientist, AI Start-up",
        stats:
          "Coaching has been shown to result in a 173% improvement in interpersonal skills, which are essential for building impactful relationships with stakeholders and enhancing influence in the workplace. (Source: Sounding Board Inc.)",
        buttonText: "Join our Communication Skills Programme",
        cta: "Struggling with communication? Coaching can help you build confidence and clarity in every interaction.",
      },
    },
    workLifeBalance: {
      high: {
        message:
          "You've developed strong work-life integration, but increasing responsibilities can challenge even the best systems. Coaching can help you maintain balance while scaling your impact.",
        actions:
          "A coach can help you:\n• Create scalable productivity systems\n• Lead by example in sustainable success\n• Help you to build resilience\n• Maintain balance during growth\n• Develop organisational wellbeing practices",
        testimonial:
          "Had good boundaries but saw them cracking as we scaled. My coach helped me build systems that actually get stronger under pressure. Now the whole team's more productive and balanced - we're hitting bigger targets while protecting our energy and avoiding burnout.",
        attribution: "CTO, Series C Start-up",
        stats:
          "70% of those who receive coaching improved their work performance, indicating a substantial boost in productivity and overall impact in the workplace (Source: Institute of Coaching, cited by Luisa Zhou)",
        buttonText: "Join our Work-Life Balance Programme",
        cta: "You're handling things well! Coaching can help you sustain balance while increasing your impact.",
      },
      medium: {
        message:
          "You're managing, but it feels harder than it should be. Without better systems, you risk hitting a wall when pressure increases or new opportunities arise.",
        actions:
          "A coach can help you:\n• Create effective boundaries\n• Build energy management skills\n• Develop better work systems\n• Handle pressure more effectively\n• Design sustainable practices",
        testimonial:
          "Kept telling myself 'it's just a busy period' - for two years straight. My coach helped me see I was confusing being busy with being effective. We rebuilt my whole approach to work - proper boundaries, strategic delegation, actual downtime. Getting more done in less time now.",
        attribution: "Head of Sales, Tech Scale-up",
        stats:
          "70% of those who receive coaching improved their work performance, indicating a substantial boost in productivity and overall impact in the workplace (Source: Institute of Coaching, cited by Luisa Zhou)",
        buttonText: "Join our Work-Life Balance Programme",
        cta: "You're holding it together, but coaching can help you create a more sustainable approach to work and life.",
      },
      low: {
        message:
          "Poor work-life balance doesn't just affect your wellbeing - it impacts your performance, relationships, and long-term career sustainability.",
        actions:
          "A coach can help you:\n• Create immediate improvements\n• Build healthy boundaries\n• Manage energy not just time\n• Develop recovery practices\n• Design sustainable routines",
        testimonial:
          "Hit a proper wall - was living on caffeine, sleeping with my phone, entire life was about work. My coach helped me get real about what wasn't working and make changes before I properly burned out. Started with small wins - actual lunch breaks, then regular exercise, then proper weekends off.",
        attribution: "Product Lead, Early-stage Start-up",
        stats:
          "70% of those who receive coaching improved their work performance, indicating a substantial boost in productivity and overall impact in the workplace (Source: Institute of Coaching, cited by Luisa Zhou)",
        buttonText: "Join our Work-Life Balance Programme",
        cta: "Feeling overwhelmed? Coaching can help you reclaim balance and long-term success.",
      },
    },

    clearGoals: {
      high: {
        message:
          "You've developed strong goal-setting practices across both your professional and personal life, but as your responsibilities grow, balancing and aligning these different areas becomes increasingly complex.",
        actions:
          "A coach can help you:\n• Create holistic vision alignment across all life areas\n• Develop meaningful success metrics that reflect your values\n• Balance short and long-term goals in both work and personal domains\n• Build accountability systems that support your success\n• Create clarity around what truly matters to you",
        testimonial:
          "I was always good at setting targets, but my coach helped me see how to connect all aspects of my life. Now I make decisions that support both my career ambitions and personal fulfillment. I'm achieving more while feeling far more balanced and purposeful about everything I do.",
        attribution: "Head of Product, Fintech Scale-up",
        stats:
          "62% of coaching clients report that coaching improved their career opportunities, highlighting its effectiveness in helping individuals advance professionally. (Source: ICF Global Coaching Study)",
        buttonText: "Join our Goal Clarity Programme",
        cta: "You're already setting strong goals! Coaching can help you align and integrate them for even greater impact.",
      },
      medium: {
        message:
          "You have goals in different areas of your life, but might be missing opportunities to create meaningful integration and consistent progress. Coaching can help you develop more effective approaches to holistic goal setting and achievement.",
        actions:
          "A coach can help you:\n• Clarify what really matters to you across all life domains\n• Create meaningful objectives that support your whole self\n• Develop effective planning systems that work with your lifestyle\n• Build better accountability for both professional and personal goals\n• Overcome implementation barriers in all areas of life",
        testimonial:
          "I had plenty of ambition but struggled with follow-through, especially balancing work goals with personal ones. My coach helped me create a realistic approach that integrated everything important to me. The difference in my consistency has been incredible - actually completing things that matter in all areas of my life.",
        attribution: "Marketing Director, E-commerce Company",
        stats:
          "62% of coaching clients report that coaching improved their career opportunities, highlighting its effectiveness in helping individuals advance professionally. (Source: ICF Global Coaching Study)",
        buttonText: "Join our Goal Clarity Programme",
        cta: "You're making progress! Coaching can help you refine your goal-setting approach and ensure meaningful follow-through.",
      },
      low: {
        message:
          "Without clear goals and effective systems across both your personal and professional life, you might find yourself working hard but not making meaningful progress in the areas that truly matter.",
        actions:
          "A coach can help you:\n• Identify what success looks like for you in all areas of life\n• Create meaningful objectives that reflect your whole self\n• Develop planning processes that support integration\n• Build momentum with small wins in both work and personal domains\n• Track progress effectively across your entire life",
        testimonial:
          "Felt like I was just reacting to whatever came my way each day, with work always taking priority by default. My coach helped me get clear on my priorities across my whole life and create proper goals for the stuff that really matters. Now I've got a clear direction in both my career and personal life and I finally feel like I’m making progress.",
        attribution: "Operations Manager, Tech Start-up",
        stats:
          "62% of coaching clients report that coaching improved their career opportunities, highlighting its effectiveness in helping individuals advance professionally. (Source: ICF Global Coaching Study)",
        buttonText: "Join our Goal Clarity Programme",
        cta: "Feeling stuck? Coaching can help you create clear goals and a system to achieve them effectively.",
      },
    },
    relationships: {
      high: {
        message:
          "You have strong relationship and conflict resolution skills, but as situations become more complex, maintaining these capabilities while handling increasing responsibilities requires continued development.",
        actions:
          "A coach can help you:\n• Navigate complex interpersonal dynamics with greater finesse\n• Transform conflicts into opportunities for deeper connection\n• Develop better collaborative problem-solving approaches\n• Build high-trust environments even during challenging times\n• Create resilient relationships that withstand pressure",
        testimonial:
          "I was good at building relationships but struggled when things got tense. My coach helped me see conflict as an opportunity rather than a threat. Now I can navigate difficult conversations with confidence while strengthening connections in the process. This has transformed how my team approaches challenges together.",
        attribution: "Head of Operations, Tech Scale-up",
        stats:
          "77% of coachees reported improved relationships with direct reports, which is critical for influencing stakeholders and fostering collaboration during organisational change (Source: Wasylyshyn, 2003, cited in ICF Research Portal)",
        buttonText: "Join our Relationship Mastery Programme",
        cta: "You're already great at building relationships! Coaching can help you refine your skills for even greater collaboration and resilience.",
      },
      medium: {
        message:
          "You have some effective relationship skills, but might find certain interpersonal situations challenging. Without developing these areas, you may miss opportunities for deeper connection and collaborative problem-solving.",
        actions:
          "A coach can help you:\n• Build confidence in addressing difficult conversations\n• Develop emotional intelligence during challenging interactions\n• Create frameworks for constructive conflict resolution\n• Strengthen relationship skills during problem-solving\n• Build resilience in the face of interpersonal challenges",
        testimonial:
          "I was fine with relationships when everything was going well, but would avoid conflict at all costs. My coach helped me develop the confidence to address issues directly while maintaining connection. Recently navigated a tricky situation with a colleague that previously would have festered for months. The relief is incredible.",
        attribution: "Senior Product Manager, E-commerce Company",
        stats:
          "77% of coachees reported improved relationships with direct reports, which is critical for influencing stakeholders and fostering collaboration during organisational change (Source: Wasylyshyn, 2003, cited in ICF Research Portal)",
        buttonText: "Join our Relationship Mastery Programme",
        cta: "You're making progress! Coaching can help you strengthen your interpersonal skills and handle difficult situations with confidence.",
      },
      low: {
        message:
          "Challenges with building relationships and handling conflict can significantly impact both your professional effectiveness and personal wellbeing, creating patterns that become increasingly difficult to change.",
        actions:
          "A coach can help you:\n• Understand your relationship patterns and triggers\n• Build fundamental conflict resolution skills\n• Develop confidence in difficult conversations\n• Create strategies for building trust and connection\n• Transform your approach to collaborative problem-solving",
        testimonial:
          "I used to either avoid conflicts entirely or handle them poorly, which was affecting all my relationships. My coach helped me understand my patterns and develop new approaches. Started with small challenges and built up my confidence. Recently resolved a major issue that would have previously sent me into a panic. The difference in how people respond to me now is remarkable.",
        attribution: "Technical Lead, SaaS Start-up",
        stats:
          "77% of coachees reported improved relationships with direct reports, which is critical for influencing stakeholders and fostering collaboration during organisational change (Source: Wasylyshyn, 2003, cited in ICF Research Portal)",
        buttonText: "Join our Relationship Mastery Programme",
        cta: "Struggling with relationships or conflict? Coaching can help you build confidence and transform your approach to connection.",
      },
    },
  };

  const level = score >= 89 ? "high" : score >= 51 ? "medium" : "low";
  return feedback[category]?.[level] || feedback.careerProgression[level];
};

export const diagnosisQuizSections = {
  "career-progression": [0, 1, 2],
  "communication-skills": [0, 1, 2],
  "work-life-balance": [0, 1, 2],
  "clear-goals": [0, 1, 2],
  relationships: [0, 1, 2],
};
