import { HtmlMeta } from "$/components/HtmlMeta";
import { CoachingDiagnosisQuiz } from "./components/CoachingDiagnosisQuiz";
import { Layout } from "antd";

export const DiagnosisQuiz = () => {
  return (
    <Layout>
      <HtmlMeta title="Diagnosis tool" />
      <div className="w-full">
        <CoachingDiagnosisQuiz />
      </div>
    </Layout>
  );
};
