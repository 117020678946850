import { useListCoachTagsQuery } from "$/graphql/types.generated";
import { useState, Dispatch, SetStateAction, useEffect } from "react";
import cn from "classnames";
import { LoadingSpinner } from "../LoadingSpinner";

export type Tags = {
  topics: Array<string>;
  approaches: Array<string>;
  specialities: Array<string>;
  experiences: Array<string>;
  packages: Array<string>;
};

export const initialTags = {
  topics: [],
  approaches: [],
  specialities: [],
  experiences: [],
  packages: [],
};

export const explorationSessionTags = {
  topics: [],
  approaches: [],
  specialities: [],
  experiences: [],
  packages: ["204f531c-d866-4057-94e1-0c994e94618c"],
};

export const TagList = ({
  id,
  title,
  list,
  selected,
  onSelect,
}: {
  id: keyof Tags;
  title?: string;
  list: Array<{ id: string; label: string }>;
  selected: Array<string>;
  onSelect: Dispatch<SetStateAction<Tags>>;
}) => {
  const handleClick = (toUpdate: string) => {
    onSelect((prev) => {
      const obj = prev[id];
      if (obj.includes(toUpdate)) {
        return { ...prev, [id]: obj.filter((item) => item !== toUpdate) };
      } else {
        return { ...prev, [id]: [...obj, toUpdate] };
      }
    });
  };

  return (
    <div className="w-full md:basis-[48%]">
      <h2>{title}</h2>
      <div className="flex flex-wrap gap-2">
        {list.map((item) => (
          <div
            className={cn("bg-beige px-2 py-1 rounded-md cursor-pointer", {
              "bg-yellow": selected?.includes(item.id),
            })}
            onClick={() => handleClick(item.id)}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export function TopicSelector({ selectedTags, handleChange }) {
  const { data, isLoading } = useListCoachTagsQuery();

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="flex flex-wrap justify-between gap-y-8">
          <TagList
            id="topics"
            title="Topics"
            list={data?.listCoachCategories}
            selected={selectedTags.topics}
            onSelect={handleChange}
            key="topics"
          />
          <TagList
            id="approaches"
            title="Approach"
            list={data?.listCoachApproaches}
            selected={selectedTags.approaches}
            onSelect={handleChange}
            key="approaches"
          />
          <TagList
            id="specialities"
            title="Specialities"
            list={data?.listCoachSpecialisms}
            selected={selectedTags.specialities}
            onSelect={handleChange}
            key="specialities"
          />
          <TagList
            id="experiences"
            title="Experience with"
            list={data?.listCoachAudienceExperiences}
            selected={selectedTags.experiences}
            onSelect={handleChange}
            key="experiences"
          />
        </div>
      )}
    </div>
  );
}
